import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from 'react-router-dom';

import history from "../../Utils/history";

// hooks
import useApi from "../../Hooks/useApiGlobal.js";
import { updateModalsOpen, updateGlobalStoreLogFromPage, updateGlobalPreviousPath } from "../../Store/action";

// templates
import Template404 from '../../Templates/Template404';
import TemplateHome from '../../Templates/TemplateHome';
import TemplateContentPage from '../../Templates/TemplateContentPage';
import TemplateRegister from '../../Templates/TemplateRegister';
import TemplateAccountHome from '../../Templates/TemplateAccountHome';
import TemplateAccountFavorite from '../../Templates/TemplateAccountFavorite';
import TemplateAccountNotif from '../../Templates/TemplateAccountNotif';
import TemplateAccountPersonnalInfo from '../../Templates/TemplateAccountPersonnalInfo'
import TemplateAccountMyInfo from '../../Templates/TemplateAccountMyInfo'
import TemplateAccountEconomy from "../../Templates/TemplateAccountEconomy";
import TemplateAccountCommunity from "../../Templates/TemplateAccountCommunity";
import TemplateSearch from '../../Templates/TemplateSearch';
import TemplateArticle from '../../Templates/TemplateArticle';
import TemplateVideo from '../../Templates/TemplateVideo';
import TemplateDiy from '../../Templates/TemplateDiy';
import TemplateProduct from '../../Templates/TemplateProduct';
import TemplateBrands from '../../Templates/TemplateBrands'
import TemplateAVosMarques from '../../Templates/TemplateAVosMarques'
import {
    TemplateContactHome,
    TemplateContactCall,
    TemplateContactCallbackRequest,
    TemplateContactEmail,
    TemplateContactDoseur
} from '../../Templates/TemplateContactHome'
import TemplateUnivers from '../../Templates/TemplateUnivers'
import TemplateTipsAndTricks from "../../Templates/TemplateTipsAndTricks";
import TemplateSitetmap from "../../Templates/TemplateSitemap";
import TemplateMentions from "../../Templates/TemplateMentions";
import TemplateEconomyWebcoupon from '../../Templates/TemplateEconomyWebcoupon';
import TemplateEconomyCashback from '../../Templates/TemplateEconomyCashback';
import TemplateCartCashback from "../../Templates/TemplateCartCashback";
import TemplateCartWebcoupon from "../../Templates/TemplateCartWebcoupon";
import TemplateLandingPage from "../../Templates/TemplateLandingPage";
import TemplateOperationsPage from "../../Templates/TemplateOperationsPage";
import { TemplateThanksEmail, TemplateThanksPrint } from "../../Templates/TemplateThanks"; // eslint-disable-line
import TemplateRegisterConfirm from '../../Templates/TemplateRegisterConfirm';
import TemplatePasswordReset from '../../Templates/TemplatePasswordReset';
import TemplateTipsAndTricksGlobal from '../../Templates/TemplateTipsAndTricksGlobal';
import TemplateMosaic from '../../Templates/TemplateMosaic';
import TemplateCommunityHP from '../../Templates/TemplateCommunityHP';
import TemplateCommunityQuestionDetails from '../../Templates/TemplateCommunityQuestionDetails';
import TemplateCommunityTipDetails from '../../Templates/TemplateCommunityTipDetails';
import TemplateCommunityFormQuestion from '../../Templates/TemplateCommunityFormQuestion';
import TemplateCommunityFormAstuce from '../../Templates/TemplateCommunityFormAstuce';
import TemplateCommunityHPQuestions from '../../Templates/TemplateCommunityHPQuestions';
import TemplateCommunityHPAstuces from '../../Templates/TemplateCommunityHPAstuces';
import TemplateCommunitySearchQuestion from '../../Templates/TemplateCommunitySearchQuestion';
import TemplateCommunitySearchAstuce from '../../Templates/TemplateCommunitySearchAstuce';

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
    'lba_common.404_page': Template404,
    'entity.canonical.node.special.homepage': TemplateHome,
    'entity.canonical.node.product': TemplateProduct,
    'entity.canonical.node.brand_page': TemplateBrands,
    'entity.canonical.taxonomy_term.universe': TemplateUnivers,
    'entity.canonical.node.special.brands_landing': TemplateAVosMarques,
    'entity.canonical.node.diy': TemplateDiy,
    'entity.canonical.node.article_video': TemplateVideo,
    'entity.canonical.node.article': TemplateArticle,
    'entity.canonical.node.special.register': TemplateRegister,
    'entity.canonical.node.special.account_notifications': TemplateAccountNotif,
    'entity.canonical.node.special.account_economy': TemplateAccountEconomy,
    'entity.canonical.node.special.tips_and_tricks': TemplateTipsAndTricks,
    'entity.canonical.node.special.tips_and_tricks_filtered_hub': TemplateTipsAndTricks,
    'entity.canonical.node.special.tips_and_tricks_filtered_universe': TemplateTipsAndTricks,
    'entity.canonical.node.special.account_edit': TemplateAccountPersonnalInfo,
    'entity.canonical.node.special.account_view': TemplateAccountMyInfo,
    'entity.canonical.node.special.account_favorites': TemplateAccountFavorite,
    'entity.canonical.node.special.account_community': TemplateAccountCommunity,
    'entity.canonical.node.page': TemplateContentPage,
    'entity.canonical.node.special.sitemap': TemplateSitetmap,
    'entity.canonical.node.accordions_page': TemplateMentions,
    'entity.canonical.node.special.contact_home': TemplateContactHome,
    'entity.canonical.node.special.contact_phone_call': TemplateContactCall,
    'entity.canonical.node.special.contact_callback_request': TemplateContactCallbackRequest,
    'entity.canonical.node.special.contact_doser': TemplateContactDoseur,
    'entity.canonical.node.special.contact_email': TemplateContactEmail,
    'entity.canonical.node.special.account_home': TemplateAccountHome,
    'entity.canonical.node.special.search_results': TemplateSearch,
    'entity.canonical.node.special.economy_webcoupon': TemplateEconomyWebcoupon,
    'lba_economy.economy_landing_url': TemplateEconomyWebcoupon,
    'entity.canonical.node.special.economy_cashback': TemplateEconomyCashback,
    'entity.canonical.node.special.cart_cashback': TemplateCartCashback,
    'entity.canonical.node.special.cart_webcoupon': TemplateCartWebcoupon,
    'entity.canonical.node.special.email_webcoupons': TemplateThanksEmail,
    'entity.canonical.node.special.print_webcoupons': TemplateThanksPrint,
    'lba_common.email_validation': TemplateRegisterConfirm,
    'lba_common.password_reset_form': TemplatePasswordReset,
    'entity.canonical.node.landing_page': TemplateLandingPage,
    // 'entity.canonical.node.operation_page': TemplateOperationsPage,
    'entity.operation.canonical': TemplateOperationsPage,
    'entity.operation.action.canonical': TemplateOperationsPage,
    'entity.canonical.node.special.tips_and_tricks_filtered_universe_paginated': TemplateTipsAndTricksGlobal,
    'entity.canonical.node.special.tips_and_tricks_filtered_hub_paginated': TemplateTipsAndTricksGlobal,
    'entity.canonical.node.special.tips_and_tricks_paginated': TemplateTipsAndTricksGlobal,
    'entity.canonical.node.mosaic': TemplateMosaic,
    'entity.canonical.node.special.community_homepage': TemplateCommunityHP,
    'entity.canonical.node.question': TemplateCommunityQuestionDetails,
    'entity.canonical.node.tip': TemplateCommunityTipDetails,
    'entity.canonical.node.special.community_post_question': TemplateCommunityFormQuestion,
    'entity.canonical.node.special.community_post_tip': TemplateCommunityFormAstuce,
    'entity.canonical.node.special.community_questions': TemplateCommunityHPQuestions,
    'entity.canonical.node.special.community_questions_filtered': TemplateCommunityHPQuestions,
    'entity.canonical.node.special.community_tips': TemplateCommunityHPAstuces,
    'entity.canonical.node.special.community_tips_filtered': TemplateCommunityHPAstuces,
    'entity.canonical.node.special.community_search_question': TemplateCommunitySearchQuestion,
    'entity.canonical.node.special.community_search_tip': TemplateCommunitySearchAstuce,

};

/**
 * @component
 * @description creer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes
 */

let lastUuidValue = null;
function RouteForTemplates(route) {
    let search = useLocation().search; // si recherche type http://monurl.com/recherche?=texte
    let hash = useLocation().hash; // si recherche avec # dans l'url
    let dispatch = useDispatch();
    let location = useLocation();
    let currentUrl = useLocation().pathname;
    // Modal info
    const modalsOpen = useSelector(state => state.modals).openModal;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [_uid, set_uid] = useState('');
    const [paramsDecoupledRouter, setDecoupledRouter] = useState({});
    const [dataDecoupledRouter, decoupledRouterIsLoaded] = useApi(paramsDecoupledRouter);

    const [paramsPopins, setParamsPopins] = useState({});
    const [dataPopins, popinsIsLoaded] = useApi(paramsPopins);
    const [paramsPopinEconomy, setParamsPopinEconomy] = useState({});
    const [dataPopinsEco, popinsEcoIsLoaded] = useApi(paramsPopinEconomy);
    const [lastRouteComponent, setLastRouteComponent] = useState({'pathname':history.location.pathname, 'search': history.location.search});
    let {uuid} = useSelector(state => state.user);
    // GET POPINS INFOS ON PAGE CHANGED
    // console.log(location.pathname);
    // console.log(route.component)
    useEffect(() => {
        let sessionLastRouteComponent = null;

        try{
            if(sessionStorage.getItem("lastPageViewTemplate")){
                sessionLastRouteComponent = JSON.parse(sessionStorage.getItem("lastPageViewTemplate"));
            }
        }
        catch{
            
        }
        
        // setPreviousSearchname(history.location.search);
        if(route.component !="entity.operation.canonical" && route.component != "entity.operation.action.canonical" && route.component != "entity.canonical.node.special.register") {
            if (sessionStorage.getItem("pageViews")) {
                sessionStorage.setItem("pageViews", parseInt(sessionStorage.getItem("pageViews")) + 1);
            } else {
                sessionStorage.setItem("pageViews", 1);
            }
            if (parseInt(sessionStorage.getItem("pageViews")) === 2) {
                // Detect when user reached the second page view of the siteweb and display popin 
                setParamsPopins({
                    method: 'get',
                    name: `api/popins?event=nav`
                });
            }
        }
        
        if (!sessionStorage.getItem("popinEconomyView") && uuid && route.component !== "entity.canonical.node.special.cart_cashback" && route.component !== "entity.canonical.node.special.cart_webcoupon" && route.component !== "entity.canonical.node.special.economy_cashback" && ((sessionLastRouteComponent?.pathname && sessionLastRouteComponent.pathname.includes("economies") == true  && !location.pathname.includes('economies')) || (sessionLastRouteComponent?.search && sessionLastRouteComponent.search.includes("step=") == true && location.pathname.includes('economies') == false))) { 
            setParamsPopinEconomy({
                method: 'get',
                name: `api/popins?event=economy`
            })
        }

        sessionStorage.setItem("lastPageViewTemplate", JSON.stringify(lastRouteComponent));
        setLastRouteComponent({'pathname': history.location.pathname, 'search': history.location.search});
    }, [history]);
    
    // DISPLAY POPINS
    useEffect(() => {
        if (dataPopins) {
            if (dataPopins.data.popin === "login") {
                dispatch(updateModalsOpen({ ...modalsOpen, 'login': true, 'noRedirection': true }));
            }

            let promotionJSON = localStorage.getItem("popinPromotionView");
            let urlOperationPresent = "";
            let isUrlOperationAbsent = false;
            
            try{
                if(promotionJSON){
                    promotionJSON = JSON.parse(promotionJSON);
                } else {
                    promotionJSON = {};
                }
            }
            catch(e){
                promotionJSON = {};
            }

            if(dataPopins?.data?.cta_link){
                urlOperationPresent = dataPopins?.data?.cta_link;
                if(!promotionJSON[urlOperationPresent]){
                    isUrlOperationAbsent = true;
                    promotionJSON[urlOperationPresent] = 1;
                }
            }
            
            if (dataPopins.data.popin === "operation" && !sessionStorage.getItem("popinEconomyView")&& isUrlOperationAbsent) {
                localStorage.setItem("popinPromotionView", JSON.stringify(promotionJSON))
                dispatch(updateModalsOpen({ ...modalsOpen, 'pageviews': true, value: dataPopins }));
            }

            if (dataPopins.data.popin === "welcome" && !sessionStorage.getItem("popinEconomyView")&& !sessionStorage.getItem("popinWelcomeView")) {
                sessionStorage.setItem("popinWelcomeView", true)
                dispatch(updateModalsOpen({ ...modalsOpen, 'pageviews': true, value: dataPopins }));
            }

            if (dataPopins.data.popin === "profile_completion" && !sessionStorage.getItem("popinCompletionView")) {
                sessionStorage.setItem("popinCompletionView", true)
                dispatch(updateModalsOpen({ ...modalsOpen, 'pageviews': true, value: dataPopins }));
            }
        }
    }, [dataPopins, popinsIsLoaded]);

    // Display popin when user leaves economy template && if he has product(s) in his basket
    useEffect(() => {
        return() => {
            if (dataPopinsEco && uuid && !sessionStorage.getItem("popinEconomyView")) {
                if (!Array.isArray(dataPopinsEco.data)) {
                    sessionStorage.setItem("popinEconomyView", true)
                    dispatch(updateModalsOpen({ ...modalsOpen, 'offerBasket': true, value: dataPopinsEco || {}}));
                }
            }
        }
    }, [dataPopinsEco, popinsEcoIsLoaded])
    // !POPIN ECONOMY 
    
    useEffect(() => {
        return () => {
            dispatch(updateGlobalPreviousPath(currentUrl));
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (uuid !== lastUuidValue) {
            setParamsPopins({
                method: 'get',
                name: `api/popins?event=post-connection`
            });
            lastUuidValue = uuid;
        }
    }, [uuid]); // eslint-disable-line

    useEffect(() => {
        if (route.computedMatch.params && !route.exact || route.component == "entity.operation.canonical") {
            history.replace({ pathname: route.computedMatch.url, search, hash });

            if (route.component !== "entity.canonical.node.special.economy_webcoupon" && route.component !== "entity.canonical.node.special.economy_cashback") {
                // Call api
                setDecoupledRouter({
                    method: 'get',
                    name: `router/translate-path?path=${route.computedMatch.url}`
                });
            }
            else {
                set_uid(route.uid);
            }
        } else {
            if (route['redirect']) {
                history.replace({ pathname: route['redirect'], search, hash });
            }
            else {
                history.replace({ pathname: route['path'], search, hash });
            }
            set_uid(route.uid);
        }

        if (hash) {
            // console.log('hash', hash)
        }

        if (window.location.href.indexOf("show_login") > -1 && route.component !== "entity.operation.canonical" && !modalIsOpen) {
            dispatch(updateModalsOpen({ ...modalsOpen, 'login': true }));
            dispatch(updateGlobalStoreLogFromPage(currentUrl));
            setModalIsOpen(true);
        }
    }, [search, hash, _uid, route]); // eslint-disable-line


    useEffect(() => {
        if (decoupledRouterIsLoaded && dataDecoupledRouter) {
            if (dataDecoupledRouter.status === 200) {
                set_uid(dataDecoupledRouter.data.entity.uuid)
            }
            else {
                history.push({pathname: "/*"});
                window.location.reload(true);

            }
        }
    }, [decoupledRouterIsLoaded, dataDecoupledRouter]);


    const renderRoute = () => {
        const Component = DynamicTemplate[route.component];
        if (Component) {
            return (
                <Route path={route['path']} name={route.component} exact={route.exact} render={() =>
                    React.createElement(DynamicTemplate[route.component], {
                        key: route.uid,
                        ...route.datas,
                        _uid: _uid
                    })
                } />
            );

        } else {
            // Composant non trouvé, vous pouvez afficher une page d'erreur ou rediriger vers une page par défaut
            return <Route component={Template404} />;
        }
    };

    return (
        <>
            {_uid !== '' && renderRoute()}
        </>
    );
}

export default RouteForTemplates;