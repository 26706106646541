import { NavLink, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Article from '../Components/article';
import CLink from '../Components/cLinks';
import ListUniverse from '../Components/listUniverse';
import { Loader } from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import build from 'redux-object';
import { camelize } from '../Utils/transformText';
import chatBoxManager from "../Utils/chatBoxManager";
import { getClientCredential } from '../Utils/getClientService';
import { getRefreshToken } from '../Utils/cookie';
import normalize from 'json-api-normalizer';
import { updateGlobalStorePage } from '../Store/action';
import useApi from '../Hooks/useApiGlobal';

// import useApi from '../Hooks/useApi';
// import Metatags from '../Layout/metas-tags/meta-tags';






//HOOKS






// Components






// Utilis


const TemplateTipsAndTricks = ({ _uid }) => {
  let dispatch = useDispatch();
  const { chatbot } = useSelector(state => state.global).global;

  useEffect(() => {
    dispatch(updateGlobalStorePage('t-tips'));
  }, [dispatch]); // isLoaded

  // Breakpoint
  const { desktop } = useSelector((state) => state.breakpoint);
  const location = useLocation();
  const limit=12;//Nombre de resultats par page
  const pageVisible = 9; //Nb Impair necessaire

  const [listArticles, setListArticles] = useState({});//Liste des articles
  const [pagesArray, setPagesArray] = useState([]);//Liste des pages
  const [pagesPrevious, setPagesPrevious] = useState(false);//Liste des pages
  const [pagesNext, setPagesNext] = useState(false);//Liste des pages

  //Pour filtrer Recup univers et hub
  const [filterElt, setFilterElt] = useState({
    univers: '',
    hub: '',
    pagination: "&limit="+limit+"&page=0"
  });
  const consumerId = getClientCredential(getRefreshToken, 'client_credentials');

  const [showLoader, setShowLoader] = useState(true);
  const [dataPage, setDataPage] = useState();
  const [dataFetchPage, isLoadedPage] = useApi({
    name: 'api/node/special',
    _uid: _uid,
    fields: '&fields[node--special]=metatag',
    method: 'get',
  });

  let url = new URL (document.location.href);
  let page = url.searchParams.get("page");

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (isLoadedPage && dataFetchPage) {
      const normalizeJson = normalize(dataFetchPage.data); //Normalisation du json
      let dataPage = build(normalizeJson, 'nodeSpecial', _uid)
      let indexCanonical = dataPage.metatag.findIndex((tag)=>tag.attributes.rel ==="canonical")
      if(indexCanonical > -1){
        let url = new URL(window.location)
        dataPage.metatag[indexCanonical].attributes.href = url.href
      }
      setDataPage(dataPage);
      // window.history.replaceState('', '', location.pathname+'?page='+parseInt(1));
    }
    }, [isLoadedPage, dataFetchPage]);

  //Requete pour récup les infos de l'univers
  const [universRedux, setUniversRedux] = useState(''); // Infos Articles
  const [dataFetchUnivers, isLoadedUnivers] = useApi({
    name: 'api/taxonomy_term/universe',
    fields:
      '&fields[taxonomy_term--universe]=name,field_api_remoteid_universeid,drupal_internal__tid,path,field_common_txt_slug',
    method: 'get',
  });

  useEffect(() => {
    if (isLoadedUnivers && dataFetchUnivers) {
      let normalizeJsonUnivers = normalize(dataFetchUnivers.data); //Normalisation du json
      setUniversRedux(build(normalizeJsonUnivers, 'taxonomyTermUniverse'));
    }
  }, [isLoadedUnivers, dataFetchUnivers]); //chargement requete ok

  //Requete pour récup les hub
  const [hubRedux, setHubRedux] = useState(); //Infos Liste Hub
  const [dataFetchHub, isLoadedHub] = useApi({
    name: 'api/taxonomy_term/hub',
    // fields:'&fields[taxonomy_term--hub]=name,taxonomy_term--universe.name,taxonomy_term--universe.path,taxonomy_term--universe.field_api_remoteid_universeid,taxonomy_term--universe.drupal_internal__tid',
    include: 'field_api_tr_universe',
    method: 'get',
  });

  //Normalize les hub
  useEffect(() => {
    if (isLoadedHub && dataFetchHub) {
      const normalizeJsonHub = normalize(dataFetchHub.data); //Normalisation du json
      setHubRedux(build(normalizeJsonHub, 'taxonomyTermHub'));
    }
  }, [isLoadedHub, dataFetchHub]); //chargement requete ok

  const [decoupledApi, setDecoupledApi] = useState({});
  useEffect(() => {
    if (hubRedux && universRedux) {
      setDecoupledApi({
          name: 'router/translate-path',
          path: location.pathname,
          method: 'get',
      });
    }
  }, [hubRedux, universRedux]);
  const [dataDecoupled, isLoadedDecoupled] = useApi(decoupledApi);

  const [decoupledApi2, setDecoupledApi2] = useState({});
  useEffect(() => {
    if (hubRedux && universRedux) {
      setDecoupledApi2({
          name: 'router/translate-path',
          path: document.location.pathname,
          method: 'get',
      });
    }
  }, [hubRedux, universRedux, document.location.pathname]);
  const [dataDecoupled2, isLoadedDecoupled2] = useApi(decoupledApi2);

  const [title, setTitle] = useState({});
  useEffect(() => {
    if (dataDecoupled2 && isLoadedDecoupled2) {
      let tempTitle = {};

      if(dataDecoupled2.data.lba_articles?.tipsAndTricksFilters?.hub?.label){
        tempTitle.labelHub = dataDecoupled2.data.lba_articles?.tipsAndTricksFilters?.hub?.label
      } else if(dataDecoupled2.data.lba_articles?.tipsAndTricksFilters?.universe?.label){
        tempTitle.labelUnivers = dataDecoupled2.data.lba_articles?.tipsAndTricksFilters?.universe?.label
      } 

      setTitle(tempTitle);
    }
  }, [dataDecoupled2, isLoadedDecoupled2]);
  

  //Requete qui permet de recup les articles
  const [paramsApiArticle, setParamsApiArticle] = useState({
    fields:
    '&fields[node--article_video]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes,field_common_video&fields[node--article]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes&fields[node--diy]=field_common_txt_title,field_common_pgph_ban,field_common_duration,drupal_internal__nid,path,field_common_tr_universes&fields[file--file]=status&fields[paragraph--responsive_image]=field_respimg_img_desktop,field_respimg_img_mobile&fields[taxonomy_term--universe]=name',
    include:
    'field_common_pgph_ban.field_respimg_img_mobile,field_common_pgph_ban.field_respimg_img_desktop,field_common_tr_universes',
    method: 'get',
    consumerId: consumerId.client_id,
    pagination: "&limit="+limit+"&page=" + (page ? page-1 : 0)
  });
  const [dataFetchArticles, isLoadedArticles] = useApi(paramsApiArticle);

  useEffect(() => {
    if (dataDecoupled) {
      if (dataDecoupled.data && dataDecoupled.data.lba_articles) {
        const universeItem = universRedux.find((element) => element.drupalInternalTid === filterElt.univers);
        setFilterElt({
          univers: 
            dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe
            ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe.tid
            : '',
          slugUnivers: universeItem ? universeItem.fieldCommonTxtSlug : '',
          labelUnivers: 
            dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe 
            ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.universe.label 
            : '',
          hub:
            dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub
            ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub.tid
            : '',
          labelHub: 
            dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub 
            ? dataDecoupled.data.lba_articles.tipsAndTricksFilters.hub.label 
            : '',
          pagination: "&limit="+limit+"&page=" + (page ? page-1 : 0)
        });
      }
      else {
        setFilterElt({...filterElt, pagination: "&limit="+limit+"&page=" + (page ? page-1 : 0)});
        setParamsApiArticle({
          ...paramsApiArticle,
          name: 'api/lba-articles/all',
          pagination: "&limit="+limit+"&page=" + (page ? page-1 : 0)
        })
      }
    }
  }, [dataDecoupled, isLoadedDecoupled]);


  useEffect(() => {
    if (isLoadedArticles && dataFetchArticles) {
      const listArticles = [];
      const pageNumbers = [];
      if (dataFetchArticles && dataFetchArticles.data && dataFetchArticles.data.results && dataFetchArticles.data.results_nb > 0) {
        dataFetchArticles.data.results.forEach(function (item, index) {
          let contentType = camelize(item.data.type);
          let normalizeJsonArticles = normalize(item); //Normalisation du json
          listArticles.push(build(normalizeJsonArticles, contentType)[0]);
        });

        let currentPage = dataFetchArticles.data.current_page;
        let pivot = Math.floor(pageVisible / 2);
        let pagetotal = Math.ceil(dataFetchArticles.data.results_nb / limit);

        let indexDebut = currentPage - pivot > 1 ? currentPage - pivot : 1;
        // On doit aussi prendre en compte la fin des pages, on va comparer la currentPage et le nb de page total par rapport au pivot
        indexDebut = pagetotal - currentPage < pivot ? pagetotal - pageVisible + 1 : indexDebut;
        // Enfin au cas ou il y aurait moins de page que la valeur pageVisible, on fixe indexDebut à 1
        indexDebut = pagetotal <= pageVisible ? 1 : indexDebut;

        if(indexDebut>1){//On affiche la fleche et les point de suspensions PREVIOUS
          setPagesPrevious(true)
        }
        else {
          setPagesPrevious(false)
        }
        if(currentPage < pagetotal - pivot){//On affiche la fleche et les point de suspensions NEXT
          setPagesNext(true)
        }
        else {
          setPagesNext(false)
        }

        for(let i = 0 ; i < pageVisible && indexDebut + i <= pagetotal ; i++){
          pageNumbers.push(indexDebut + i);
        }
      }
      setListArticles(listArticles);
      setShowLoader(false);
      setPagesArray(pageNumbers);
      setCurrentPage(dataFetchArticles.data.current_page)
    }
  }, [isLoadedArticles, limit]);

  useEffect(() => {
    if (filterElt && isLoadedDecoupled) {
      setShowLoader(true);
      chatBoxManager.reset()
            if(hubRedux){
                const hub = hubRedux.find(element => element.drupalInternalTid === filterElt.hub);
                if(hub){
                    let globalTags = chatbot.hub_terms.map(hub => hub.uuid)
                    chatBoxManager.check([hub], globalTags);
                }
            }
      setPagesArray([])

      // let univers = filterElt.univers;
      // let hub = filterElt.hub ? filterElt.hub : "";
      // let keykey = univers + hub;
      // let isChanged = (window.keykey !== keykey);
      // window.keykey = keykey;

      let page = url.searchParams.get("page");
      if(page){
        paramsApiArticle.pagination = "&limit="+limit+"&page="+ (page - 1);
      } else {
        paramsApiArticle.pagination = "&limit="+limit+"&page="+ 0;
      }


      if (filterElt.hub && filterElt.hub !== '') {
        setParamsApiArticle({ ...paramsApiArticle, name: 'api/lba-articles/all', filter:'&hub=' + filterElt.hub, pagination: filterElt.pagination ? paramsApiArticle.pagination : paramsApiArticle.pagination });
      } else if (filterElt.univers && filterElt.univers !== '') {
        setParamsApiArticle({ ...paramsApiArticle, name: 'api/lba-articles/all', filter:'&universe=' + filterElt.univers, pagination: filterElt.pagination ? paramsApiArticle.pagination : paramsApiArticle.pagination });
      } else {
        setParamsApiArticle({ ...paramsApiArticle, name: 'api/lba-articles/all', filter:'', pagination: filterElt.pagination ? paramsApiArticle.pagination : paramsApiArticle.pagination });
      }
    }
  }, [filterElt, isLoadedDecoupled]);

  const onChangePage = (currentPage) => {
    setShowLoader(true);
    window.history.replaceState('', '', location.pathname+'?page='+parseInt(currentPage+1));
    setParamsApiArticle({ ...paramsApiArticle, pagination: "&limit="+limit+"&page="+currentPage });
  }

  const renderPage = () => {
    const { metatag } = dataPage
    let customTitle = null;  
    let customMetaDescription = null; 

    if(title.labelHub || title.labelUnivers){
      customTitle = 
        title.labelHub 
        ? `Conseils et astuces : ${title.labelHub}` 
        : title.labelUnivers
        ?  `Conseils et astuces : ${title.labelUnivers}`
        :  "Conseils et astuces" ; 
      customMetaDescription = 
        title.labelHub 
        ? `Retrouvez tous nos conseils et astuces ${title.labelHub} par La Belle Adresse` 
        : title.labelUnivers
        ?  `Retrouvez tous nos conseils et astuces ${title.labelUnivers} par La Belle Adresse` 
        :  `Retrouvez tous nos conseils et astuces par La Belle Adresse`;
    } else {
      customTitle = "Conseils et astuces";
      customMetaDescription = "Retrouvez tous nos conseils et astuces par La Belle Adresse";
    }
    let pageviewEvent = {
      category: 'Conseils et Astuces',
      category2: '',
      category3: '',
    };

    return (
      <>
        {metatag && <Metatags listMeta={metatag} pageviewEvent={pageviewEvent} customTitle={customTitle} customMetaDescription={customMetaDescription}/>}
        <main className="main">
            {universRedux && hubRedux && (
                <div className="container">
                    <h2 className="section-title">Univers</h2>
                    <ListUniverse
                    universList={universRedux}
                    desktop={desktop}
                    subuniverses={hubRedux}
                    filterElt={filterElt}
                    setFilterElt={setFilterElt}
                    showLoader={showLoader}
                    url='/tous-les-conseils-astuces/'
                    />
                </div>
            )}
            <div className="container">
                {listArticles && (
                    <List
                        list={listArticles}
                        listName="celaPourraitVousPlaire"
                        title="Cela pourrait vous plaire"
                        showLoader={showLoader}
                        desktop={desktop}
                    />
                )}

            
                <div className="c-pagination">
                    {(pagesArray.length > 1 && !showLoader) &&
                        <div className="c-pagination_content">
                            {
                                pagesPrevious && <NavLink to={`${document.location.pathname}?page=${currentPage}`} className="c-pagination_prev" onClick={() => onChangePage(currentPage - 1)}>
                                  <i className="icon-arrow-rounded" /> ...
                                </NavLink>
                                    // <button className="c-pagination_prev" onClick={() => onChangePage(currentPage - 1)}></button>
                            }
                            <ul>
                                {pagesArray.map((number, key) => {
                                    return (
                                        <NavLink
                                          key={key}
                                          to={`${document.location.pathname}?page=${number}`}
                                          className={currentPage === number-1 ? 'current--' : ''}
                                          onClick={() => onChangePage(number-1)}
                                        >
                                          {number}
                                        </NavLink>
                                    )
                                })}
                            </ul>
                            {
                                pagesNext && <NavLink to={`${document.location.pathname}?page=${currentPage + 2}`} className="c-pagination_next" onClick={() => onChangePage(currentPage + 1)}>
                                          ... <i className="icon-arrow-rounded" />
                                </NavLink>
                                    // <button className="c-pagination_next" onClick={() => onChangePage(currentPage + 1)}></button>
                            }
                        </div>
                        
                    }
                    <CLink url="/conseils-astuces" title="Voir notre sélection" className="c-button btn-2" />
                </div>
            </div>
        </main>
      </>
    );
  };
  // return (isLoadedPage && dataPage && isLoadedUnivers && isLoadedHub) ? renderPage() : <Loader/>
  return isLoadedPage && dataPage ? renderPage() : <Loader />;
};

const List = ({ showLoader, list }) => {
  return (
    <>
        {showLoader ? (
            <Loader />
        ):
        list.length > 0 ? (
            <>
                <div className="c-listArticle">
                    {list.map((item, key) => {
                        return (
                            <Article key={key} {...item} />
                        );
                    })}
                </div>
            </>
        ) : (
            <p className="c-carouselArticles-noresults">Aucun article ne correspond à la recherche</p>
        )}
    </>
  );
};

export default TemplateTipsAndTricks;