import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
// Hooks
import { updateModalsOpen } from "../../Store/action";
import useTracking from '../../Hooks/useTracking';
// Components
import Modal from "./modal";
import CLink from "../cLinks";
// Libraries
import { randomString } from "../../Utils/md5-hash";


const OfferBasketModal = () => {

    let dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;
    const currentPage = useSelector(state => state.page).categories;

    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent);

    const handleClosePopin = () => {
        dispatch(updateModalsOpen({ ...modalsOpen, 'offerBasket': false }));

        setTrackingEvent({
            event: "popin_click",
            args: {
                popin_name: "offerBasket",
                content_category: currentPage?.category,
                content_category2: currentPage?.category2,
                content_category3: currentPage?.category3,
                popin_action: "cta_click",
                popin_cta_url: modalsOpen.value.data.cta_link || '',
            },
            refresh: randomString(6)
        });
    }

    return (
        <>
            {
                modalsOpen && modalsOpen.value && 
                <Modal modalName="offerBasket" modalNameTracking="offerBasket">
                    <div className="c-offerBasket">
                        <div className="c-modal_offerBasket-intro">
                            <h2 className="title-1">Vous nous quittez déjà&nbsp;?</h2>
                            <p>Avant de partir, n'oubliez pas de <strong>profiter des offres de réductions</strong> disponibles dans votre panier</p>
                            {
                                modalsOpen.value.data && modalsOpen.value.data.cta_link && 
                                <CLink 
                                    url={modalsOpen.value.data.cta_link} 
                                    title={"J'accède à mon panier"}
                                    className="btn-3" 
                                    handleClick={() => handleClosePopin()}
                                />
                            }
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
};

export default OfferBasketModal;