import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { updateModalsOpen } from "../../Store/action";
import useTracking from '../../Hooks/useTracking';

// Libraries
import { randomString } from "../../Utils/md5-hash";

const Modal = ({ modalName, modalNameTracking, children, disableClosing }) => {

    let dispatch = useDispatch();
    const modalEl = useRef();
    const history = useHistory();
    const modalsOpen = useSelector(state => state.modals).openModal;
    const loginType = useSelector(state => state.modals).loginType;
    const currentPage = useSelector(state => state.page).categories;
    
    
    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent); // eslint-disable-line

    // Body scroll lock
    if (modalsOpen[modalName]) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "";
    }
    

    // TRACKING - Popin display
    useEffect(() => {
        if (modalsOpen[modalName]) {
            setTrackingEvent({
                event: 'popin_display',
                args: {
                    popin_name: modalNameTracking,
                    content_category: currentPage.category,
                    content_category2: currentPage.category2,
                    content_category3: currentPage.category3,
                },
                refresh: randomString(6)
            });
        }
    }, [modalsOpen]); // eslint-disable-line


    const handleTracking = (action, ctaUrl) => {
        setTrackingEvent({
            event: "popin_click",
            args: {
                popin_name: modalNameTracking,
                content_category: currentPage.category,
                content_category2: currentPage.category2,
                content_category3: currentPage.category3,
                popin_action: action,
                popin_cta_url: ctaUrl || '',
            },
            refresh: randomString(6)
        });
    };

    const closeModal = (actionTracking) => {
        if (!disableClosing) {
            let newState = {
                ...modalsOpen,
                [modalName]: false,
                'hasError': false
            };
            dispatch(updateModalsOpen(newState));
            handleTracking(actionTracking, 'no redirection');
            
            if (modalName === "pageviews" || modalName === "offerBasket" || modalsOpen.noRedirection) { }
            else if (loginType === "account" /*|| loginType === "login" || (loginType === "operation" && window.location.href.indexOf('/bienvenue') < 0)*/) {
                //Passage mis en commentaire au dessus. Voulu initialement, mais finalement non ... a voir si il faut remettre
                history.replace({ pathname: '/' });
            }
        }
    };

    return (
        <>
            {modalsOpen[modalName] &&
                <div className={`c-modal fade c-modal_${modalName} ${modalsOpen[modalName] ? 'show--' : ''}`} tabIndex="-1"
                    role="dialog" ref={modalEl}>
                    <div
                        className={`c-modal_overlay fade-- ${modalsOpen[modalName] ? 'show--' : ''} ${disableClosing ? 'noClick--' : ''}`}
                        onClick={() => closeModal("ignore")}>&nbsp;</div>
                    <div className="c-modal_container" role="document">
                        <div className="c-modal_content">
                            {!disableClosing &&
                                <button type="button" className="c-modal_close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal("close")}><i className="icon-close"></i></button>
                            }
                            <div className="c-modal_body">{children}</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default Modal;
