import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Hooks
import { updateModalsOpen } from "../../Store/action";
import useTracking from '../../Hooks/useTracking';
// Components
import Modal from "./modal";
import CLink from "../cLinks";
// Libraries
import { randomString } from "../../Utils/md5-hash";

const PageViewsModal = () => {

    let dispatch = useDispatch();
    const modalsOpen = useSelector(state => state.modals).openModal;
    const type = modalsOpen?.value?.data?.popin;

    const currentPage = useSelector(state => state.page).categories;

    const [trackingEvent, setTrackingEvent] = useState({});
    const tracking = useTracking(trackingEvent);

    const handleClosePopin = () => {
        setTrackingEvent({
            event: "popin_click",
            args: {
                popin_name: type ? type : "page vues",
                content_category: currentPage?.category,
                content_category2: currentPage?.category2,
                content_category3: currentPage?.category3,
                popin_action: "cta_click",
                popin_cta_url: modalsOpen.value.data.cta_link || modalsOpen.value.data.cta_url || '',
            },
            refresh: randomString(6)
        });
        dispatch(updateModalsOpen({ ...modalsOpen, 'pageviews': false }));
    }

    return (
        <>
            {
                modalsOpen.value &&
                <Modal modalName="pageviews" modalNameTracking={type ? type : "page vues"}>
                    <div className="c-pageviews">
                        <div className="c-modal_pageviews-intro">
                            {
                                modalsOpen.value.data.title &&
                                <h2 className="title-1">{modalsOpen.value.data.title}</h2>
                            }
                            {
                                modalsOpen.value.data.text &&
                                <div dangerouslySetInnerHTML={{ __html: modalsOpen.value.data.text }}></div>
                            }
                            {
                                modalsOpen.value.data.image &&
                                <img src={modalsOpen.value.data.image} className="c-modal_pageviews-img" alt="" />
                            }
                            {
                                (modalsOpen.value.data.cta_link || modalsOpen.value.data.cta_url) &&
                                <CLink
                                    url={type === "profile_completion" ? modalsOpen.value.data.cta_url.replace(document.location.origin, "") : modalsOpen.value.data.cta_link + "#more-info-profil"}
                                    //url={modalsOpen.value.data.cta_link + (type === "profile_completion" ? "#more-info-profil" : "")}
                                    title={modalsOpen.value.data.cta_label ? modalsOpen.value.data.cta_label : "J'en profite"}
                                    className="btn-3"
                                    handleClick={() => handleClosePopin()}
                                />
                            }
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
};

export default PageViewsModal;