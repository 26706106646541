import React, {useEffect, useState} from 'react';

import {Helmet} from "react-helmet";
import {useSelector} from 'react-redux';

const Metatags = ({listMeta, pageviewEvent, seoTitle, customTitle, customMetaDescription}) => {
    let title = "La Belle Adresse";
    // let description = "";
    let meta = [];
    let links = [];

    listMeta && listMeta.map(({tag, attributes}) => {
        switch (tag) {
            case "title" :
                if (seoTitle && seoTitle !== "undefined") {
                    title = seoTitle;
                } else if (customTitle){
                    title = customTitle
                } else {
                    title = attributes.content;
                }
                break;
            case "link" :
                links.push(attributes);
                break;
            case "meta" :
                if(attributes.name === "title"){
                    title = attributes.content;
                } else {
                    meta.push(attributes);
                }

                
                break;
            default :
                break;
        }
        return null;
    });

    if (customMetaDescription) {
        meta.push({ name: 'description', content: customMetaDescription });
    }

    if (customTitle) {
        title = customTitle
    }

    // Get user state
    let {iupid, pseudo} = useSelector(state => state.user);
    const [userId, setUserId] = useState(undefined);

    useEffect(() => {
        if (iupid && pseudo) {
            // User ID si connecté
            setUserId(iupid);
        }
    }, [iupid, pseudo]);

    // Récupérer le titre de la page
    const [pageTitle, setPageTitle] = useState('');
    useEffect(() => {     
        meta.map((item) => {
            // get meta og:title
            if (item.property === 'og:title') {
                setPageTitle(item.content);
            }

            if(customTitle){
                if (item.name === "title") {
                    item.content = customTitle;
                    title = customTitle;
                }
                if (item.property === 'og:title') {
                    item.content = customTitle;
                    setPageTitle(customTitle);
                }
            } else if(seoTitle && seoTitle !== "undefined") {
                if (item.name === "title") {
                    item.content = seoTitle;
                    title = seoTitle;
                }
                if (item.property === 'og:title') {
                    item.content = seoTitle;
                    setPageTitle(seoTitle);
                }
            } else{
                if (item.name === "title") {
                    title = item.content;
                }
            }

            return null;
        });        
    }, [meta]);

    // Tracking - content_category
    const [enableTracking, setEnableTracking] = useState(true);
    const [category, setCategory] = useState('');
    const [category2, setCategory2] = useState(undefined);
    const [category3, setCategory3] = useState(undefined);

    useEffect(() => {
       
        // content_category
        if (pageviewEvent && pageviewEvent.category) {
            // props envoyés
            setCategory(pageviewEvent.category)
        } else {
            if (pageTitle) {
                // sinon on récupère le titre de la page via les metatags
                setCategory(pageTitle)
            }
        }

        // content_category2
        if (pageviewEvent && pageviewEvent.category2) setCategory2(pageviewEvent.category2);
        // content_category3
        if (pageviewEvent && pageviewEvent.category3) setCategory3(pageviewEvent.category3);

    }, [pageTitle, category, category2, category3]); // eslint-disable-line

    const onHelmetChange = () => {

        // TRACKING - Page view
        if (enableTracking && category) {
            window.customDataLayer.push({
                event: 'page_view',
                content_category: category,
                content_category2: category2,
                content_category3: category3,
                user_id: userId,
            });
            // console.log('customDataLayer', window.customDataLayer);

            if(!window.historique_url){
                window.historique_url = [];
            }
            window.historique_url.push(document.location.href);
            
            setEnableTracking(false);
        }
    };

    return (

        <Helmet htmlAttributes={{lang: 'fr'}} title={title} meta={meta} link={links}
                onChangeClientState={onHelmetChange}/>

    );
};

export default Metatags;